import React from 'react';

const Contact = () => {
  return (
    <section id="contacto" className="bg-darkBlue-100 py-16">
      <div className="container mx-auto px-4">
        <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-xl transition-shadow duration-300">
          <h2 className="text-3xl font-bold mb-6 text-darkBlue-800 text-center animate-fade-in">Contacto</h2>
          <div className="flex flex-col md:flex-row justify-center items-start">
            <div className="md:w-1/2 pr-8 mb-8 md:mb-0">
              <p className="text-gray-700 text-center mb-4 animate-fade-in">
                Estamos emocionados ante la oportunidad de dialogar cómo podemos contribuir al éxito de su empresa. Por favor, no dude en ponerse en contacto con nosotros:
              </p>
              <div className="flex items-center mb-4 animate-slide-in-left">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-darkBlue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                <a href="mailto:contacto@jvcoordinacion.com" className="text-darkBlue-600 hover:underline transition-colors">
                  contacto@jvcoordinacion.com
                </a>
              </div>
              <div className="flex items-center animate-slide-in-left">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-darkBlue-600" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                </svg>
                <a
                  href="https://wa.me/5491151625183"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-darkBlue-600 hover:underline transition-colors"
                >
                  +5491151625183
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;