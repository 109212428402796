import React from 'react';

const Services = () => {
  return (
    <section className="py-16 bg-gradient-to-b from-white to-darkBlue-100">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <ServiceCard
            title="Compromiso con la Calidad y la Personalización"
            content="En J.V. Coordinación, nos dedicamos a proporcionar un servicio de transporte terrestre de primera línea a todos los clientes, forwarder y despachantes, donde la satisfacción del cliente es nuestra máxima prioridad."
            imageSrc="/fotocalle.PNG"
            imageAlt="Calle con camiones"
          />
          <ServiceCard
            title="Proveedores"
            content="En J.V. gestionamos una amplia cartera de proveedores, con más de 400 camiones, cumpliendo estrictamente todas las normativas y estándares de seguridad en las cargas. Nuestras unidades están habilitadas para el transporte de cargas peligrosas, refrigeradas, extra-medidas y contamos con depósitos para desconsolidación, almacenamiento y plazoleta para contenedores."
            imageSrc="/camiones1.PNG"
            imageAlt="Flota de camiones"
            imageLeft={false}
          />
        </div>
      </div>
    </section>
  );
};

const ServiceCard = ({ title, content, imageSrc, imageAlt, imageLeft = true }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-xl transition-shadow duration-300">
      <div className="flex flex-col md:flex-row items-center">
        {imageLeft ? (
          <>
            <div className="md:w-1/2 pr-8">
              <h2 className="text-3xl font-bold mb-6 text-darkBlue-800 animate-slide-in-left">{title}</h2>
              <p className="text-gray-700 animate-fade-in">{content}</p>
            </div>
            <div className="md:w-1/2 mt-8 md:mt-0">
              <img src={imageSrc} alt={imageAlt} className="w-full h-auto rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 animate-slide-in-right" />
            </div>
          </>
        ) : (
          <>
            <div className="md:w-1/2 mt-8 md:mt-0 md:order-1">
              <img src={imageSrc} alt={imageAlt} className="w-full h-auto rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 animate-slide-in-left" />
            </div>
            <div className="md:w-1/2 pr-8">
              <h2 className="text-3xl font-bold mb-6 text-darkBlue-800 animate-slide-in-right">{title}</h2>
              <p className="text-gray-700 animate-fade-in">{content}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Services;