import React from 'react';
import { BiLogoKickstarter } from "react-icons/bi";

function Footer() {
  return (
    <footer className="bg-gray-800 text-white py-2">
      <div className="container mx-auto px-4 flex justify-center items-center">
        <a href="https://kevinbnanezdev.web.app/" target="_blank" rel="noopener noreferrer" className="flex items-center hover:text-gray-300 transition duration-300">
          <BiLogoKickstarter className="mr-2" />
          <span className="text-sm">Diseño web</span>
        </a>
      </div>
    </footer>
  );
}

export default Footer;