import React from 'react';

const AboutUs = () => {
  return (
    <section className="py-16 bg-gradient-to-b from-darkBlue-100 to-white">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-xl transition-shadow duration-300">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 pr-8">
                <h2 className="text-3xl font-bold mb-6 text-darkBlue-800 animate-slide-in-left">Por qué elegirnos</h2>
                <p className="text-gray-700 animate-fade-in">
                  Nuestro enfoque se centra en comprender las necesidades únicas de cada cliente y superar sus expectativas. A continuación encontrará enlaces a cartas de recomendación tanto de clientes como de empresas respaldando la calidad y fiabilidad de nuestro servicio.
                </p>
                <div className="mt-4 flex items-center space-x-4">
                  <a href="/IVECO.pdf" target="_blank" rel="noopener noreferrer" className="transform hover:scale-105 transition-transform">
                    <img src="/iveco2.jpg" alt="IVECO" className="h-12 w-auto hover:opacity-80 transition-opacity" />
                  </a>
                  <a href="/ROBINSON.pdf" target="_blank" rel="noopener noreferrer" className="transform hover:scale-105 transition-transform">
                    <img src="/ROBINSON.jpg" alt="ROBINSON" className="h-12 w-auto hover:opacity-80 transition-opacity" />
                  </a>
                  <a href="/MARS.pdf" target="_blank" rel="noopener noreferrer" className="transform hover:scale-105 transition-transform">
                    <img src="/MARS.jpeg" alt="MARS" className="h-12 w-auto hover:opacity-80 transition-opacity" />
                  </a>
                </div>
              </div>
              <div className="md:w-1/2 mt-8 md:mt-0">
                <img src="/bump.jpg" alt="Por qué elegirnos" className="w-full h-auto rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 animate-slide-in-right" />
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-xl transition-shadow duration-300">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 pr-8">
                <h2 className="text-3xl font-bold mb-6 text-darkBlue-800 animate-fade-in">Tarifas Competitivas</h2>
                <p className="text-gray-700 animate-fade-in">
                  Ofrecemos tarifas de mercado altamente competitivas, permitiendo a nuestros clientes administrar sus gastos logísticos de manera eficiente.
                </p>
              </div>
              <div className="md:w-1/2 mt-8 md:mt-0">
                <img
                  src="/fotonegocio.PNG"
                  alt="Negocio"
                  className="w-full h-auto rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 animate-slide-in-right"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;