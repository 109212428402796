import React from 'react';
import Header from '../Components/Header';
import Hero from '../Components/Hero';
import Services from '../Components/Services';
import AboutUs from '../Components/AboutUs';
import Contact from '../Components/Contact';
import Footer from '../Components/Footer';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-white">
      <Header />
      <main>
        <Hero />
        <Services />
        <AboutUs />
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default LandingPage;