import React, { useState, useEffect } from 'react';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToContact = () => {
    const contactSection = document.getElementById('contacto');
    contactSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <header className={`bg-darkBlue-700 shadow fixed w-full z-10 transition-all duration-300 ${isScrolled ? 'py-2' : 'py-4'}`}>
      <nav className="container mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
        <div className="flex items-center mb-2 sm:mb-0">
          <div className="relative w-16 h-16 sm:w-20 sm:h-20 mr-3 overflow-hidden rounded-full bg-white flex items-center justify-center">
            <img 
              src="/logo.png" 
              alt="J.V COORDINACION Logo" 
              className="h-full w-auto object-cover object-left"
            />
          </div>
          <h1 className="text-xl sm:text-2xl font-bold text-white transition-all duration-300 hover:text-darkBlue-200">
            J.V COORDINACION
            <span className="block text-sm sm:text-base font-semibold">
              Transformando la logística
            </span>
          </h1>
        </div>

        <div className="flex items-center space-x-2 sm:space-x-4">
          <button
            onClick={scrollToContact}
            className="px-3 py-1 sm:px-6 sm:py-2 bg-white text-darkBlue-700 text-sm sm:text-base font-semibold rounded-full hover:bg-darkBlue-100 transition duration-300 transform hover:scale-105"
          >
            Contacto
          </button>
          <a
            href="https://wa.me/541151625183"
            title="Contactar por Whatsapp"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-darkBlue-100 transition-colors transform hover:scale-110"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
            </svg>
          </a>
        </div>
      </nav>
    </header>
  );
};

export default Header;