import React from 'react';

const Hero = () => {
  return (
    <section className="relative h-screen">
      <img src="/camion1.jpeg" alt="Camión" className="w-full h-full object-cover" />
      <div className="absolute inset-0 bg-darkBlue-900 bg-opacity-60 flex flex-col items-center justify-center px-4">
        <h1 className="text-5xl font-bold text-white text-center leading-tight mb-6 animate-fade-in-down">
          Servicio de coordinación de transporte terrestre
        </h1>
        <p className="text-white text-center max-w-3xl animate-fade-in-up">
          Con más de cinco años de experiencia en la coordinación de cargas FCL/LCL a lo largo y ancho del país, en empresas tanto nacionales como multinacionales, nuestra trayectoria se ha caracterizado por un compromiso con la excelencia en el servicio. Además de nuestra experiencia en la coordinación logística, hemos cultivado una sólida habilidad en el área de Customer Service, brindando apoyo a grandes empresas como Iveco Group, CNH Industrial, Exologistica, PepsiCo, Masterfoods, entre otros.
        </p>
      </div>
    </section>
  );
};

export default Hero;